import { DropdownMenu } from "@kobalte/core";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { KeyboardShortcutPill } from "~/components/pills/KeyboardShortcutPill";
import { type PromptSettings, usePromptContext } from "../PromptContext";

export const ChatSettingsMenu = () => {
  const { promptRef, promptSettings, setPromptSettings } = usePromptContext();
  return (
    <DropdownMenu.Root placement="top" preventScroll={false}>
      <DropdownMenu.Trigger class="px-1 rounded-md flex items-center gap-1 border border-transparent bg-gray-200 dark:bg-slate-700 text-slate-600 dark:text-slate-200 hover:bg-gray-300 dark:hover:bg-slate-600">
        <DropdownMenu.Icon>
          <Icon name={IconName.Cog} size={IconsSize.Micro} />
        </DropdownMenu.Icon>
        <span>Settings</span>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal mount={promptRef()}>
        <DropdownMenu.Content class="text-xs border flex flex-col bg-white dark:bg-slate-800 dark:border-slate-700 text-slate-800 dark:text-slate-200 animate-fade-out kb-expanded:animate-fade-in rounded">
          <DropdownMenu.Group class="p-4">
            <DropdownMenu.GroupLabel class="mb-2">Choose how you want to submit prompts</DropdownMenu.GroupLabel>
            <DropdownMenu.RadioGroup
              value={promptSettings.submitKeybinding}
              onChange={(val) => setPromptSettings("submitKeybinding", val as PromptSettings["submitKeybinding"])}
            >
              <DropdownMenu.RadioItem
                class="cursor-pointer flex items-center justify-between gap-4 pl-8 pr-4 py-2 text-2xs rounded relative"
                value="enter"
              >
                <DropdownMenu.ItemIndicator class="scale-75 absolute left-0">
                  <Icon name={IconName.CheckCircle} size={IconsSize.Micro} />
                </DropdownMenu.ItemIndicator>
                <span>
                  Press <KeyboardShortcutPill keys={["Enter"]} class="text-[10px]" /> to send.
                </span>
              </DropdownMenu.RadioItem>
              <DropdownMenu.RadioItem
                class="cursor-pointer flex items-center justify-between gap-4 pl-8 pr-4 py-2 text-2xs rounded relative"
                value="shift+enter"
              >
                <DropdownMenu.ItemIndicator class="scale-75 absolute left-0">
                  <Icon name={IconName.CheckCircle} size={IconsSize.Micro} />
                </DropdownMenu.ItemIndicator>
                <span>
                  Press <KeyboardShortcutPill keys={["Shift", "Enter"]} class="text-[10px]" /> to send.
                </span>
              </DropdownMenu.RadioItem>
            </DropdownMenu.RadioGroup>
          </DropdownMenu.Group>
          <DropdownMenu.Arrow />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
