import type { IconBaseProps, IconProps, IconTree } from "solid-icons";
import { type JSX, createEffect, createMemo, createSignal, mergeProps, onCleanup, splitProps } from "solid-js";
import { isServer, ssr } from "solid-js/web";

export function CustomSolidIcon(iconSrc: IconTree, props: IconProps): JSX.Element {
  const mergedProps = mergeProps(iconSrc.a, props) as IconBaseProps;
  const [_, svgProps] = splitProps(mergedProps, ["src"]);
  const [content, setContent] = createSignal<string>("");
  const rawContent = createMemo(() => (props.title ? `${iconSrc.c}<title>${props.title}</title>` : iconSrc.c));

  createEffect(() => setContent(rawContent()));

  onCleanup(() => {
    setContent("");
  });

  return (
    <svg
      stroke={iconSrc.a?.stroke}
      color={props.color || "currentColor"}
      fill={props.color || "currentColor"}
      stroke-width="0"
      style={{
        ...props.style,
        overflow: "visible",
      }}
      {...svgProps}
      height={props.size || "1em"}
      width={props.size || "1em"}
      xmlns="http://www.w3.org/2000/svg"
      innerHTML={content()}
    >
      {isServer ? (ssr(rawContent()) as unknown as string) : undefined}
    </svg>
  );
}
