import { type Component, Show, type ParentComponent, createSignal, type JSXElement } from "solid-js";
import { Presence, Motion } from "solid-motionone";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { createElementSize } from "@solid-primitives/resize-observer";
import { ChatQuestionLibrary } from "./ChatQuestionLibrary";
import { ChatSettingsMenu } from "./ChatSettings";
import { usePromptContext } from "../PromptContext";

export const ChatTopBar: Component = () => {
  const { focused, showQuestionLibrary, setShowQuestionLibrary } = usePromptContext();
  const [ref, setRef] = createSignal<HTMLDivElement>();
  const size = createElementSize(ref);

  return (
    <Presence exitBeforeEnter>
      <Show when={focused()}>
        <Motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            height: showQuestionLibrary() ? `${size.height}px` : "36px",
          }}
          exit={{ opacity: 0, height: 0 }}
          class="relative overflow-y-hidden"
        >
          <div
            ref={setRef}
            class="absolute top-0 left-0 right-0 text-2xs text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-slate-900 rounded-t"
          >
            <div class="flex items-center justify-between gap-2 h-9 px-2">
              <ToolbarButton
                id="prompt-library-toggle"
                testId="prompt-library-toggle"
                onClick={() => setShowQuestionLibrary(!showQuestionLibrary())}
                icon={
                  <Icon
                    class="transition-transform"
                    classList={{
                      "rotate-180": showQuestionLibrary(),
                    }}
                    name={IconName.ChevronUp}
                    size={IconsSize.Micro}
                  />
                }
              >
                Question Library
              </ToolbarButton>

              <ChatSettingsMenu />
            </div>

            <Show when={showQuestionLibrary()}>
              <ChatQuestionLibrary />
            </Show>
          </div>
        </Motion.div>
      </Show>
    </Presence>
  );
};

const ToolbarButton: ParentComponent<{
  onClick?: () => void;
  active?: boolean;
  icon?: JSXElement;
  id?: string;
  testId?: string;
}> = (props) => (
  <button
    id={props.id}
    data-testid={props.testId}
    type="button"
    onClick={props.onClick}
    class="px-1 rounded-md flex items-center gap-1 border border-transparent"
    classList={{
      "bg-gray-200 dark:bg-slate-800 text-slate-700 dark:text-slate-200 hover:bg-gray-300 dark:hover:bg-slate-700":
        !props.active,
      "bg-blue-200 dark:bg-blue-900 text-blue-600 dark:text-blue-100 border border-blue-700": props.active,
    }}
  >
    <Show when={props.icon}>{props.icon}</Show>
    {props.children}
  </button>
);

const LLMSelector = () => (
  <div class="flex items-center gap-2">
    <div class="mr-1">Chat powered by</div>

    <ToolbarButton active>Claude-3.0 Haiku</ToolbarButton>
    <ToolbarButton>GPT-3.5 Turbo</ToolbarButton>
  </div>
);
