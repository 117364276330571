import { Tabs } from "@kobalte/core";
import type { IconTypes } from "solid-icons";
import { For, Show, type Component } from "solid-js";
import { twMerge } from "tailwind-merge";
import { StIcon } from "../icons";
import { type ComponentTheme, getThemeClasses } from "~/lib/theme";

export type StTabItem = {
  label: string;
  value: string;
  icon?: IconTypes;
};

//#region theme
const tabBaseTheme = getThemeClasses({
  light: ["text-indigo-950"],
  dark: ["text-indigo-100"],
});
const tabActiveTheme = getThemeClasses({
  light: ["text-indigo-1100"],
  dark: ["text-white"],
});
const indicatorTheme = getThemeClasses({
  light: ["bg-indigo-800"],
  dark: ["bg-white"],
});
//#endregion

export const StTabs: Component<{
  active: string;
  tabs: StTabItem[];
  setTab: (tab: string) => void;
  theme: ComponentTheme;
  opts?: Tabs.TabsRootProps;
}> = (props) => {
  return (
    <Tabs.Root
      value={props.active}
      onChange={props.setTab}
      {...props.opts}
      class={twMerge("rounded-lg p-1", props.opts?.class)}
    >
      <Tabs.List class="relative flex gap-4">
        <Tabs.Indicator
          class={twMerge(
            "absolute transition-all duration-300 h-[3px] rounded-full -bottom-1",
            indicatorTheme[props.theme],
          )}
        />

        <For each={props.tabs}>
          {(tab) => (
            <Tabs.Trigger
              class={twMerge(
                "flex items-center gap-2 relative px-3 py-1 transition-all rounded-lg text-xs md:text-base",
                tab.value !== props.active && tabBaseTheme[props.theme],
                tab.value === props.active && `font-semibold ${tabActiveTheme[props.theme]}`,
              )}
              value={tab.value}
            >
              <Show when={tab.icon}>
                {(icon) => (
                  <StIcon
                    icon={icon()}
                    classList={{
                      "stroke-3": tab.value === props.active,
                    }}
                  />
                )}
              </Show>
              {tab.label}
            </Tabs.Trigger>
          )}
        </For>
      </Tabs.List>
    </Tabs.Root>
  );
};
