import { Dialog } from "@kobalte/core";
import { stAnalytics } from "@repo/analytics";
import { type Component, Match, type Setter, Switch, createEffect, createSignal, on } from "solid-js";
import { Icon, IconName } from "~/components/icons";
import { StTabs } from "~/components/tabs";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { FileDropzone } from "~/domains/marketing/components/FileDropzone";
import { PastedContentUpload } from "./PastedContentUpload";
import { ChatFullScreenFileDrop } from "./ChatFullScreenFileDrop";

export const ChatFileUpload: Component<{
  open: boolean;
  setOpen: Setter<boolean>;
  changeKnowledge: () => Promise<void>;
}> = (props) => {
  const { threadEventProps } = useThreadEventProperties();
  const onUploaded = () => {
    props.changeKnowledge();
    props.setOpen(false);
  };

  createEffect(
    on(
      () => props.open,
      (open) => {
        if (open) {
          stAnalytics.track("file_upload_modal_opened", threadEventProps());
        }
      },
    ),
  );

  const [tab, setTab] = createSignal("files");

  return (
    <>
      <Dialog.Root open={props.open} onOpenChange={props.setOpen} modal={false} preventScroll>
        <Dialog.Portal>
          <Dialog.Overlay class="fixed z-50 inset-0 bg-indigo-1100/50" />
          <div class="fixed inset-0 z-50 flex items-center justify-center">
            <Dialog.Content class="bg-indigo-800 w-full max-w-3xl text-white rounded-lg p-5 animate-fade-out kb-expanded:animate-fade-in">
              <div class="flex items-center justify-between gap-5">
                <Dialog.Title class="sr-only">Add knowledge</Dialog.Title>
                <StTabs
                  theme="dark"
                  tabs={[
                    {
                      label: "Upload files",
                      value: "files",
                    },
                    {
                      label: "Paste content",
                      value: "paste",
                    },
                  ]}
                  active={tab()}
                  setTab={setTab}
                />
                <Dialog.CloseButton class="dialog__close-button">
                  <Icon name={IconName.Xmark} />
                </Dialog.CloseButton>
              </div>

              <Switch>
                <Match when={tab() === "files"}>
                  <div class="mt-5">
                    <FileDropzone close={onUploaded} context="thread" />
                  </div>
                </Match>

                <Match when={tab() === "paste"}>
                  <div class="mt-5">
                    <PastedContentUpload context="thread" close={onUploaded} />
                  </div>
                </Match>
              </Switch>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      <ChatFullScreenFileDrop context="thread" openChatFileUplaod={() => props.setOpen(true)} />
    </>
  );
};
