import Mention from "@tiptap/extension-mention";
import { SolidRenderer, getTiptapSolidReactiveOwner } from "tiptap-solid";
import { PluginKey } from "prosemirror-state";
import { useWire } from "~/wire";
import { runWithOwner } from "solid-js";
import type { SuggestionProps } from "@tiptap/suggestion";
import { type SlashMenuProps, SlashMenuContainer } from "./SlashCommand.render";
import type { SlashCommandItemType } from "./SlashCommand.types";
import { QuestionLibraryQuestions } from "../../components/ChatQuestionLibrary";
import { insertCharacterCommand } from "../helpers";
import { usePromptContext } from "../../PromptContext";
import { stAnalytics } from "@repo/analytics";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { useLocation } from "@solidjs/router";

export type SlashCommandSuggestionProps = SuggestionProps<SlashCommandItemType>;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    SlashCommand: {
      /**
       * Comments will be added to the autocomplete.
       */
      invokeSlashCommand: () => ReturnType;
    };
  }
}

// Workaround for not knowing if the slash command menu was opened by typing or manually invoked through a command
let insertTriggeredManually = false;

export const SlashCommandExtension = Mention.extend({
  name: "SlashCommand",
  addCommands: () => ({
    invokeSlashCommand: () => {
      insertTriggeredManually = true;
      return insertCharacterCommand("/");
    },
  }),
}).configure({
  suggestion: {
    pluginKey: new PluginKey("slashCommand"),
    char: "/",
    items: async ({ query, editor }): Promise<SlashCommandItemType[]> => {
      const owner = getTiptapSolidReactiveOwner(editor);
      const prompt = runWithOwner(owner, usePromptContext);

      return [
        {
          label: "Upload documents",
          onClick: () => {
            prompt?.setShowUploadModal(true);
          },
        },
        ...QuestionLibraryQuestions.map((q) => ({
          label: `Prompt: ${q.label}`,
          noClear: true,
          onClick: () => {
            editor.commands.setContent({
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: q.prompt?.prompt,
                },
              ],
            });
          },
        })),
      ].filter((item) => item.label.toLowerCase().replaceAll(" ", "").includes(query.toLowerCase()));
    },
    render: () => {
      let solidRenderer: SolidRenderer<SlashMenuProps> | undefined;
      return {
        onStart: (props: SlashCommandSuggestionProps) => {
          solidRenderer = new SolidRenderer(SlashMenuContainer, {
            props,
            editor: props.editor,
          });
          solidRenderer.updateProps(props);

          const owner = getTiptapSolidReactiveOwner(props.editor);
          const wire = runWithOwner(owner, useWire);
          const from = insertTriggeredManually ? "toolbar" : "text";
          insertTriggeredManually = false;

          if (!wire) return;

          stAnalytics.track("prompt_slash_command_menu_opened", {
            from,
            ...getThreadEventProperties({
              workingContext: wire.services.identity.snapshot.context.identity.workingContext,
              threadId: wire.services.threads.snapshot.context.threadId,
              threadMessages: wire.services.threads.messages(),
            }),
          });
        },
        onUpdate(props: SlashCommandSuggestionProps) {
          solidRenderer?.updateProps(props);
        },
        onExit() {
          solidRenderer?.destroy();
          solidRenderer = undefined;
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            solidRenderer?.destroy();
            solidRenderer = undefined;
            return true;
          }
          return false;
        },
      };
    },
  },
  renderHTML({ node }) {
    return ["span", {}, `@${node.attrs.label || node.attrs.id}`];
  },
});
