import type { NodeViewProps } from "@tiptap/core";
import { createEffect, Match, Switch, type Component } from "solid-js";
import { NodeViewWrapper } from "tiptap-solid";
import type { ChatVariable, ChatVariableFile, ChatVariablePredefined } from "./Variables.types";
import { StButton } from "~/components/buttons";
import { StDropdown } from "~/components/popups/StDropdown";
import { usePromptContext } from "../../PromptContext";
import { TbCheck, TbPaperclip, TbSelector } from "solid-icons/tb";
import { useWire } from "~/wire";
import { useAssetUploader, WithLimitedFileTypes } from "~/domains/projects/hooks/assetUpload";
import { getContentTypes } from "@repo/mime";
import { getRequestClient, type q } from "@repo/client";
import { stAnalytics } from "@repo/analytics";

export const VariablesRenderer: Component<NodeViewProps> = (props) => {
  const variable = () => props.node.attrs as ChatVariable;
  return (
    <NodeViewWrapper class="inline-block my-[2px]">
      <Switch>
        <Match when={variable().type === "predefined"}>
          <PredefinedVariable {...props} variable={variable() as ChatVariablePredefined} />
        </Match>
        <Match when={variable().type === "file"}>
          <FileVariable {...props} variable={variable() as ChatVariableFile} />
        </Match>
        <Match when={true}>
          <span>{variable().label}</span>
        </Match>
      </Switch>
    </NodeViewWrapper>
  );
};

const FileVariable: Component<NodeViewProps & { variable: ChatVariableFile }> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.identity.getIdentityToken);

  const uploader = useAssetUploader(
    {
      logger: wire.dependencies.logger,
      wire,
    },
    WithLimitedFileTypes(getContentTypes()),
  );

  const managedAsset = () => uploader.assets()[0];

  const onFileReady = (asset: q.ControlplaneSsAsset) => {
    const pos = props.getPos();
    props.deleteNode();
    props.editor.commands.insertContentAt(
      { from: pos, to: pos },
      {
        type: "tagNode",
        attrs: {
          ...asset,
        },
      },
    );
    props.editor.commands.focus();
  };

  createEffect(() => {
    if (managedAsset() && !props.node.attrs.assetName) {
      props.updateAttributes({
        assetName: managedAsset()?.snapshot.context.uploadFile.name,
        assetId: managedAsset()?.snapshot.context.assetID,
      });
      uploader.onConfirm(false);
    }
  });

  let once = false;
  createEffect(() => {
    if (once) return;
    const id = managedAsset()?.snapshot.context.assetID;
    const finished = managedAsset()?.snapshot.context.progress === 1;
    if (!id || !finished) return;
    once = true;
    client.controlplane.GetAssetByID(id).then((r) => {
      onFileReady(r.data);
    });
  });

  return (
    <StButton
      size="sm"
      icon={TbPaperclip}
      loading={!!props.variable.assetName}
      class={props.selected ? "ring-2" : ""}
      onClick={async () => {
        if (managedAsset()) return;
        stAnalytics.track("home_page_madlib_prompt_file_variable_clicked", {
          id: props.variable.id,
        });

        await uploader.onSelectFiles();
      }}
    >
      {managedAsset() ? `${Math.round(managedAsset()?.snapshot.context.progress ?? 0)}% ` : ""}
      {props.variable.assetName || props.variable.label}
    </StButton>
  );
};

const PredefinedVariable: Component<NodeViewProps & { variable: ChatVariablePredefined }> = (props) => {
  const { promptRef } = usePromptContext();
  return (
    <StDropdown
      theme="default"
      trigger={{
        as: "span",
      }}
      opts={{
        placement: "top",
        onOpenChange: (o) => {
          if (!o) {
            setTimeout(() => {
              props.editor.commands.focus();
            }, 500);
          }
        },
      }}
      containerClass="max-h-44 overflow-y-auto"
      content={{
        class: "min-w-32",
      }}
      items={props.variable.options.map((o) => ({
        kind: "item",
        content: o.label,
        icon: o.value === props.variable.value ? TbCheck : undefined,
        props: {
          onSelect: () => {
            props.updateAttributes({ ...props.node.attrs, value: o.value });
            stAnalytics.track("home_page_madlib_prompt_list_variable_changed", {
              id: props.variable.id,
              value: o.value,
            });
          },
          class: "text-sm",
        },
      }))}
      mount={promptRef()}
    >
      <StButton size="sm" icon={TbSelector} class={props.selected ? "ring-2" : ""}>
        {props.variable.value}
      </StButton>
    </StDropdown>
  );
};
