import { stAnalytics } from "@repo/analytics";
import { call, codes, getRequestClient, type stid } from "@repo/client";
import { type Logger, Named } from "@repo/logger";
import { getContentTypes } from "@repo/mime";
import { useActor } from "@xstate/solid";
import { createSignal } from "solid-js";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { assetUploadEventFactory } from "~/domains/projects/hooks/assetUpload/assetUploadEventProducers";
import type { UploadAsset } from "~/domains/projects/hooks/assetUpload/types";
import { type UploadFile, createFileUploader } from "~/domains/projects/hooks/assetUpload/upload";
import type { Wire } from "~/wire";
import { newUploadAssetMachine } from "./assetUploadMachine";

export type Dependencies = {
  logger: Logger;
  wire: Wire;
};

export const WithSingleFile = (): OptionFn => {
  return (cfg: Config) => {
    cfg.multiple = false;
  };
};

export const WithMultipleFiles = (): OptionFn => {
  return (cfg: Config) => {
    cfg.multiple = true;
  };
};

export const WithLimitedFileTypes = (types: string[]): OptionFn => {
  return (cfg: Config) => {
    cfg.accept = types;
  };
};

export const WithHookDefaults = (): OptionFn => {
  return (cfg: Config) => {
    WithLimitedFileTypes(getContentTypes())(cfg);
    WithSingleFile()(cfg);
  };
};

const applyOptions = (...options: OptionFn[]): Config => {
  const cfg: Config = {
    multiple: false,
    accept: [],
  };
  for (const option of options) {
    option(cfg);
  }
  validateConfig(cfg);
  return cfg;
};

/**
 * useAssetUploader is a hook that provides a file uploader that can be used to create and upload one or more assets.
 * For every asset being managed, a state machine is created to manage the asset's lifecycle during upload.
 *
 * Example Usage:
 *
 * export const UploadFilesDemo = () => {
 *   const wire = useWire();
 *   const hook = useAssetUploader({
 *     logger: wire.dependencies.logger,
 *     wire,
 *   });
 *
 *   return (
 *     <Show
 *       fallback={<div>Must be authenticated...</div>}
 *       when={wire.services.identity.snapshot.context.identity.isAuthenticated}
 *     >
 *       <div class="bg-black text-white">
 *         <div>
 *           <h5>Select a single file with async callback</h5>
 *           <button type="button" class="p-2 bg-slate-50 m-2 text-black" onClick={hook.onSelectFiles}>
 *             Select File
 *           </button>
 *           <button type="button" class="p-2 bg-slate-50 m-2 text-black" onClick={hook.onConfirm}>
 *             Upload
 *           </button>
 *           <p>File count: {hook.assets().length}</p>
 *           <For each={hook.assets()}>
 *             {(asset, index) => (
 *               <div>
 *                 source: {asset.source}
 *                 <br />
 *                 originalFilename: {asset.snapshot.context.uploadFile.file.name}
 *                 <br />
 *                 size: {asset.snapshot.context.uploadFile.file.size}
 *                 <br />
 *                 contentType: {asset.snapshot.context.uploadFile.file.type}
 *                 <br />
 *                 progress: {asset.snapshot.context.progress}
 *                 <br />
 *                 state: {asset.snapshot.value}
 *                 <br />
 *                 <Show when={isPendingUpload(asset.snapshot.value)}>
 *                   <button
 *                     type="button"
 *                     onClick={() => hook.onRemoveFile(asset.source)}>
 *                     Remove From Upload Queue
 *                   </button>
 *                 </Show>
 *                 <Show when={isUploading(asset.snapshot.value)}>
 *                   <button
 *                     type="button"
 *                     onClick={() => hook.onRemoveFile(asset.source)}>
 *                     Cancel Upload
 *                   </button>
 *                 </Show>
 *               </div>
 *             )}
 *           </For>
 *         </div>
 *       </div>
 *     </Show>
 *   );
 * };
 * @param deps
 * @param options
 */
export const useAssetUploader = (deps: Dependencies, ...options: OptionFn[]) => {
  const cfg = applyOptions(WithHookDefaults(), ...options);

  const logger = new Named(deps.logger, "useAssetUploader");
  const eventFactory = assetUploadEventFactory(logger);

  const [assets, setAssets] = createSignal<ManagedAsset[]>([]);

  let processInBackground = false;

  const onReset = () => {
    setAssets([]);
  };

  // Create the file uploader using SolidJS primitives.
  const { files, selectFiles, clearFiles, removeFile } = createFileUploader({
    accept: cfg.accept.join(","),
    multiple: cfg.multiple,
  });

  /**
   * newManagedAsset creates a new managed asset from a file including the asset record and a state machine to manage
   * the asset's lifecycle during upload.
   * @param f
   */
  const newManagedAsset = (f: UploadFile): ManagedAsset => {
    const asset: UploadAsset = {
      uploadFile: f,
      creationMethod: "user_upload",
      projectId: deps.wire.services.identity.snapshot.context.identity.workingContext.projectId,
      signedURL: null,
      assetID: null,
      progress: 0,
    };
    const [snapshot, send] = createMachine(
      {
        wire: deps.wire,
        logger: deps.logger,
        eventFactory,
      },
      asset,
    );
    return {
      source: f.source,
      snapshot,
      send,
    };
  };

  /**
   * onClearFiles clears the files from the file uploader. It clears the entire index.
   */
  const onClearFiles = () => {
    logger.info("clearing files");
    setAssets([]);
    clearFiles();
  };

  const addAssets = (files: UploadFile[]) =>
    setAssets((assets) => [...assets, ...files.map((f) => newManagedAsset(f))]);

  /**
   * onRemoveFile removes a file from the file uploader. It removes the file from the index.
   * @param source
   */
  const onRemoveFile = (source: string) => {
    logger.info("removing file", { source });
    removeFile(source);
    setAssets((s) => s.filter((a) => a.source !== source));
  };

  /**
   * selectFilesCallback is a callback that is called when the user selects files. It indexes the new file.
   * @param files
   */
  const selectFilesCallback = async (files: UploadFile[]) => {
    logger.info("selectFilesCallback", { files });
    if (!files) throw new Error("files is undefined");
    const newAssets: ManagedAsset[] = [];
    for (const f of files) {
      logger.info("created newManagedAsset", { source: f.source });
      newAssets.push(newManagedAsset(f));
    }
    logger.info("adding new assets", { newAssets });
    setAssets((previous) => [...previous, ...newAssets]);
  };

  /**
   * onAbortUpload aborts an upload for a given asset. It will cancel the upload request.
   * @param source
   */
  const onAbortUpload = (source: string) => {
    const asset = assets().find((a) => a.source === source);
    if (!asset) {
      logger.warn("asset not found", { source });
      return;
    }
    if (!asset.snapshot.context.xhr) {
      logger.warn("xhr not found on asset", { source });
      return;
    }
    asset.snapshot.context.xhr.abort();
    asset.send(eventFactory.newAbortUploadEvent());
  };

  /**
   * onConfirm sends a confirmation event to the state machine to start the upload process.
   */
  const onConfirm = (isForThread: boolean) => {
    if (assets().length === 0) {
      logger.warn("no assets to confirm");
      return;
    }
    processInBackground = isForThread;
    for (const asset of assets()) {
      asset.send(eventFactory.newUserConfirmEvent());
    }
  };

  type ManagedAsset = {
    source: string;
    snapshot: ReturnType<typeof createMachine>[0];
    send: ReturnType<typeof createMachine>[1];
  };

  /**
   * createMachine creates a new state machine for a given asset. It will also mutate the asset within the
   * assets signal to include the xhr object so requests can be cancelled by the user.
   * @param deps
   * @param asset
   */
  const createMachine = (deps: CreateMachineDependencies, asset: UploadAsset) => {
    const childLogger = new Named(deps.logger, "newAssetUploadCreateMachine");

    /**
     * onUpload is a callback that is called when the asset is ready to be uploaded. It will upload the asset to the
     * signed URL provided by the server. It will also send messages to the machine to update the progress.
     * @param asset
     */
    const onUpload = async (asset: UploadAsset) => {
      if (!asset.signedURL) throw new Error("asset.signedURL is undefined");

      const handleError = (msg: string) => {
        send(deps.eventFactory.newUploadFailedEvent(msg));
      };

      const updateProgress = (e: ProgressEvent) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          send(deps.eventFactory.newUpdateProgressEvent(progress));
        }
      };

      const xhr = new XMLHttpRequest();
      xhr.open("PUT", asset.signedURL, true);
      xhr.setRequestHeader("Content-Type", asset.uploadFile.file.type);

      xhr.upload.onprogress = updateProgress;
      xhr.onloadstart = () => send(deps.eventFactory.newUpdateProgressEvent(0));
      xhr.onloadend = () => {
        childLogger.info(`uploading asset complete with status code ${xhr.status}`, asset);
        if (xhr.status !== 200) {
          send(
            deps.eventFactory.newUploadFailedEvent(
              `Storage server returned ${xhr.status} when making PUT to transmit the file`,
            ),
          );
          Sentry.captureException(new Error(`Failed to upload asset; Server responded with ${xhr.status}`));
          stAnalytics.track(
            "file_upload_failed",
            getThreadEventProperties({
              workingContext: deps.wire.services.identity.snapshot.context.identity.workingContext,
              threadId: deps.wire.services.threads.snapshot.context.threadId,
              threadMessages: deps.wire.services.threads.messages(),
            }),
          );

          return handleError(`xhr.status: ${xhr.status}`);
        }
        childLogger.info("uploading asset complete", asset);
        send(deps.eventFactory.newUploadCompleteEvent());

        stAnalytics.track("file_uploaded", {
          ...getThreadEventProperties({
            workingContext: deps.wire.services.identity.snapshot.context.identity.workingContext,
            threadId: deps.wire.services.threads.snapshot.context.threadId,
            threadMessages: deps.wire.services.threads.messages(),
          }),
          asset_id: asset.assetID,
        });
      };
      // set the xhr object on the upload so it can be cancelled.
      setAssets((assets) => {
        return assets.map((a) => {
          if (a.source === asset.uploadFile.source) {
            return {
              ...a,
              xhr,
            };
          }
          return a;
        });
      });
      // Begin the upload
      xhr.send(asset.uploadFile.file);
    };

    /**
     * createAsset creates a new asset record on the server within the project.
     * @param asset
     * */
    const onCreateAsset = async (asset: UploadAsset) => {
      childLogger.info("creating asset", asset);
      const client = getRequestClient(deps.wire.services.identity.getIdentityToken);

      stAnalytics.track(
        "file_upload_started",
        getThreadEventProperties({
          workingContext: deps.wire.services.identity.snapshot.context.identity.workingContext,
          threadId: deps.wire.services.threads.snapshot.context.threadId,
          threadMessages: deps.wire.services.threads.messages(),
        }),
      );

      const results = await call(async () => {
        childLogger.info("calling API to create asset", asset);
        return await client.controlplane.CreateAsset({
          contentType: asset.uploadFile.file.type,
          creationMethod: asset.creationMethod,
          originalFileName: asset.uploadFile.file.name,
          projectID: asset.projectId,
          permissions: [], // allow the server to assign
        });
      });
      if (results.code !== codes.OK) {
        send(deps.eventFactory.newAssetCreationFailedEvent(`API CreateAsset returned ${results.code}`));

        Sentry.captureException(new Error(`Failed to create asset; API responded with ${results.code}`));

        stAnalytics.track(
          "asset_creation_failed",
          getThreadEventProperties({
            workingContext: deps.wire.services.identity.snapshot.context.identity.workingContext,
            threadId: deps.wire.services.threads.snapshot.context.threadId,
            threadMessages: deps.wire.services.threads.messages(),
          }),
        );

        return;
      }
      send(deps.eventFactory.newAssetCreatedEvent(results.data.id, results.data.signedURL));
    };

    const onNotifyAPI = async (asset: UploadAsset) => {
      childLogger.info("notifying API of successful upload", asset);
      if (!asset.assetID) throw new Error("asset id is undefined");
      const client = getRequestClient(deps.wire.services.identity.getIdentityToken);
      const results = await call(async () => {
        if (!asset.assetID) throw new Error("assetID is undefined");
        childLogger.info("calling API to notify API", asset);
        return await client.controlplane.NotifyAssetUploaded({
          assetID: asset.assetID,
          processInBackground: processInBackground,
        });
      });
      if (results.code !== codes.OK) {
        send(
          deps.eventFactory.newUploadFailedEvent(
            `API returned ${results.code} when notifying API of the completed upload`,
          ),
        );
        return;
      }
    };

    const machine = useActor(
      newUploadAssetMachine(
        {
          logger: deps.logger,
          onUpload,
          onCreateAsset,
          onNotifyAPI,
        },
        asset,
      ),
      // {
      //   inspect: createBrowserInspector().inspect,
      // },
    );
    const [_, send] = machine;
    return machine;
  };

  return {
    assets,
    setAssets,
    addAssets,
    newManagedAsset,
    onSelectFiles: async () => selectFiles(selectFilesCallback),
    selectFiles,
    onClearFiles,
    onRemoveFile,
    onAbortUpload,
    onConfirm,
    onReset,
  };
};

type OptionFn = (config: Config) => void;

type Config = {
  multiple: boolean;
  accept: string[];
};

const validateConfig = (cfg: Config) => {
  if (cfg.accept.length === 0) {
    throw new Error("at least one content type is required");
  }
};

type CreateMachineDependencies = {
  wire: Wire;
  logger: Logger;
  eventFactory: ReturnType<typeof assetUploadEventFactory>;
};
