import { Model, type threads, ThreadMessageKinds } from "@repo/client";

export const blankKnowledge = Object.freeze({
  assetContext: {
    added: [],
    removed: [],
  },
  projectContext: {
    added: [],
    removed: [],
  },
  directoryContext: {
    added: [],
    removed: [],
  },
  organizationContext: {
    added: [],
    removed: [],
  },
  world: true,
});

export const newTransformationPrompt = (
  prompt: string,
  transformationId: string,
): threads.MessagePromptTransformationV1 => {
  return {
    kind: ThreadMessageKinds.MessageKindPromptTransformationV1,
    knowledge: { ...blankKnowledge },
    // @ts-ignore - undefined as this should only be set by server
    messageId: undefined,
    model: Model.ModelAuto,
    prompt: prompt,
    transformationId,
  };
};

export const newPromptWithDefaults = (prompt: string, transformationId: string): threads.MessagePromptV1 => ({
  kind: ThreadMessageKinds.MessageKindPromptV1,
  // @ts-ignore - undefined as this should only be set by server
  messageId: undefined,
  context_window: -1,
  frequency_penalty: -1,
  knowledge: { ...blankKnowledge },
  logit_bias: {},
  max_tokens: -1,
  model: Model.ModelAuto,
  presence_penalty: -1,
  prompt,
  transformationId,
  stop: [],
  temperature: -1,
  top_p: -1,
});
